<template>
  <v-dialog v-model="dialog" width="840" persistent transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Upload Keystore</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-container>
        <form enctype="multipart/form-data" class="ma-4">
          <input ref="fileUpload" placeholder="Selecionar arquivo" accept=".keystore" type="file" @change="onFileChange" />
        </form>

        <v-btn block color="primary" :disabled="!keystore_file || loading" class="mt-8 px-4" @click="updateKeystoreFile"
          ><v-icon class="mr-1">mdi-upload</v-icon> Salvar</v-btn
        >
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { MUTATION_UPDATE_KEYSTORE } from '@/modules/accounts/graphql'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => false
    },
    accountId: {
      type: String,
      default: () => ''
    },
    appName: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    loading: false,
    keystore_file: null
  }),
  methods: {
    close() {
      this.$emit('close')
      this.$refs.fileUpload.value = null
      this.keystore_file = null
      this.loading = false
    },
    onFileChange(e) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append(this.appName, file, `${this.appName}.keystore`)
      const data = formData.entries().next()
      this.keystore_file = data.value[1]
    },
    async updateKeystoreFile() {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_KEYSTORE,
          variables: {
            uploadKeyStoreParams: {
              accountId: this.accountId,
              file: this.keystore_file
            }
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_config']
          }
        })
        this.close()
        this.$snackbar({ message: 'Alterações salvas', snackbarColor: '#2E7D32' })
      } catch (err) {
        this.loading = false
        console.log(err?.message)
        this.$snackbar({ message: 'Falha ao salvar alterações', snackbarColor: '#F44336' })
      }
    }
  }
}
</script>
